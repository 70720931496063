import * as React from "react";
import * as styles from "./styles/index.module.css";
import classnames from "classnames/bind";
import Hero from "../components/HeroSection/Hero";
import Intro from "../components/IntroSection/Intro";
import Card from "../components/Card/Card";
import daphnePic from "../images/daphne.jpg";
import Container from "../components/Container/Container";
import Footer from "../components/Footer/Footer";
import { Link } from "gatsby";
import Button from "../components/Button/Button";
import sunshinePic from "../images/sunshine.png";
import waterPic from "../images/water-bubbles.png";
import { AccessibilityNew, FavoriteBorder } from "@material-ui/icons";
import ContactForm from "../components/ContactForm/ContactForm";

const cx = classnames.bind(styles);

const Index = () => {
  return (
    <main className={cx("base")}>
      <title>Yirpa Ministries | Home</title>
      <Hero />
      <Intro style={{ paddingBottom: "7rem" }} title={"Welkom"}>
        Yirpa is een Christelijke praktijk die natuurlijke behandelingen
        gebruikt om herstel in het lichaam te bevorderen en tot stand te
        brengen.
      </Intro>
      <div className={cx("introCards")}>
        <Card icon={<FavoriteBorder />}>
          <strong>Yirpa</strong> betekent ‘<strong>Hij zal genezen</strong>’.
          Het is een hebreeuws woord en komt uit de Bijbel. Genezing is iets
          waarnaar iedereen verlangt als hij /zij ziek is.
        </Card>
        <Card icon={<AccessibilityNew />}>
          Yirpa staat voor <strong>complete genezing</strong> die God wil
          aanbieden: heel maken wat verbroken is en genezen wat verwondt is,
          zowel lichamelijk maar ook emotioneel en geestelijk.
        </Card>
      </div>

      <div id={"whoAmI"} className={cx("aboutMe")}>
        <Container flex>
          <img src={daphnePic} />
          <div className={cx("textBlock")}>
            <h1>Daphne Ene-Christina</h1>
            <span>Hydrotherapeut en leefstijladviseur</span>
            <blockquote>
              Uit eigen ervaring heb ik gezien en ondervonden hoe ziekte op een
              holistische manier kan worden tegengegaan met behulp van
              natuurlijke middelen. Omdat dit mij erg interesseerde heb ik
              hierin een training gevolgd van 2,5 jaar aan het Uchee Pines
              Institute. Binnen het gezondheidscentrum heb ik therapie gegeven
              aan verschillende personen met diverse leefstijlziekten zoals
              diabetes, artritis, burn-out en allergieën.
            </blockquote>
          </div>
        </Container>
      </div>

      <div id={"whatIDo"} className={cx("whatIDo")}>
        <Container style={{ maxWidth: "800px" }}>
          <h1>Wat doe ik?</h1>
          <p>
            Om een goede behandeling te kunnen geven, begin ik bij het begin.
            Wat is ziekte?
          </p>
          <blockquote>
            Ziekte is een poging van de natuur om het gestel te bevrijden van
            toestanden die het gevolg zijn van het overtreden van de wetten van
            gezondheid. In geval van ziekte moet de oorzaak worden vastgesteld.
            Ongezonde omstandigheden moeten veranderd worden en verkeerde
            gewoonten gecorrigeerd. Dan moet de natuur geholpen worden in haar
            pogingen om verontreinigingen uit te scheiden en de juiste toestand
            in het gestel weer te herstellen. – De weg tot gezondheid, E.G.
            White pg 97
          </blockquote>

          <Container flex>
            <div className={cx("images")}>
              <img src={sunshinePic} />
              <img src={waterPic} />
            </div>

            <div className={cx("text")}>
              <p>
                <strong>Allereerst gaan we zoeken naar de oorzaak.</strong>
              </p>

              <p>
                Na een grondig intakegesprek stel ik een plan op aan de hand van
                de <Link to="/">NEWSTART principes</Link>, zodat ongezonde
                omstandigheden en verkeerde gewoonten aangepakt kunnen worden.
              </p>

              <p>
                Daarnaast geef ik advies over hoe we het lichaam kunnen helpen
                met het uitscheiden van verontreinigingen en met het herstel aan
                de hand van watertherapieën en kruidenbehandelingen.
              </p>

              <p>
                Indien nodig kom ik bij je thuis om eventuele{" "}
                <Link to="/">behandelingen</Link> uit te voeren en of te
                demonstreren.
              </p>

              <p>
                De bedoeling is om het plan zo op te stellen dat het{" "}
                <strong>past bij jouw persoon en situatie</strong>.
              </p>

              <Button>
                <Link to={"/therapieen/"}>Therapieën</Link>
              </Button>
            </div>
          </Container>
        </Container>
      </div>

      <ContactForm />

      <Footer />
    </main>
  );
};

export default Index;
